import { createI18n } from 'vue-i18n';
import zh from './lang/zh';
import en from './lang/en';

const messages = {
    zh: {
        ...zh
    },
    en: {
        ...en
    }
};

// 获取浏览器语言
const preferredLang = localStorage.getItem('preferred_lang');
const lang = preferredLang || (navigator.language || navigator.browserLanguage).toLowerCase();

const i18n = createI18n({
    messages,
    locale: lang.substr(0, 2),
    allowComposition: true,
    legacy: false, // 如果要支持compositionAPI，此项必须设置为false;
    globalInjection: true // 全局注册$t方法
});

export default i18n;
