<template>
    <div style="padding: 20px 50px 20px 50px">
        <el-upload
            ref="file"
            class="upload-demo"
            v-model:file-list="fileList"
            drag
            :limit="10"
            :data="data"
            multiple
            @success="handleSuccess"
            :auto-upload="false"
        >
            <template #trigger>
                <img class="el-icon--upload" style="width: 50px;" :src="require('@/assets/icon/upload-icon.png')" />
                <div class="el-upload__text" v-html="$t('file.file_2')">
                </div>
            </template>

            <el-button style="margin-top: 10px;background-color: #f7ab8e" type="success" @click="submitUpload">
                {{data.from}} to {{data.to}}
            </el-button>

            <template #tip>
                <div class="el-upload__tip">
                    {{$t('file.file_3')}}
                </div>
            </template>
        </el-upload>
    </div>
</template>

<script>
import {msgWarning} from "../../utils/message";

export default {
    name: 'TransFile',
    components: {
    },
    data() {
        return {
            dialogVisible: false,
            fileList: [],
            data: { from: '', to: '' }
        }
    },
    created() {
        console.log(this.$route.name.split['-'])
        this.data.from = this.$route.name.split('-')[0]
        this.data.to = this.$route.name.split('-')[1]

    },
    mounted() {
    },
    methods: {
        submitUpload() {
            if (this.fileList.length == 0) {
                msgWarning('请先上传文件')
                return
            }
            let data = new FormData();
            data.append("to", this.data.to);
            data.append("from", this.data.from);
            this.fileList.map(item => {
                data.append('file', item.raw)
            })
            this.$toolUtils.downloadFile('/wali/file/doTrans', data)
        },
        handleSuccess(response, file, fileList) {
            console.log('File uploaded successfully:', response, file, fileList);
        }
    }
}
</script>

<style>

</style>
