<template>
    <div></div>
</template>

<script>
export default {
    name: 'appHome',
    components: {
    },
    data() {
        return {
        }
    },
    create() {
    },
    methods: {
    }
}
</script>
