import { ElLoading } from 'element-plus'

let loadingCount = 0
let loading

const startLoading = (msg) => {
  loading = ElLoading.service({
    lock: true,
    text: msg,
    background: 'rgba(255, 255, 255, 0.8)'
  })
}

const endLoading = () => {
  loading.close()
}

export const showLoading = (msg) => {
  if (loadingCount === 0) {
    startLoading(msg)
  }
  loadingCount += 1
}

export const hideLoading = () => {
  if (loadingCount <= 0) {
    return
  }
  loadingCount -= 1
  if (loadingCount === 0) {
    endLoading()
  }
}
