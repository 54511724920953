import { createApp } from 'vue'
import i18n from "@/i18n";
import App from './App.vue'
import router from "@/router";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import toolUtils from "@/utils/toolUtils.js";
import { showLoading, hideLoading } from '@/utils/loading'
const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(ElementPlus);

app.config.globalProperties.$showLoading = showLoading
app.config.globalProperties.$hideLoading = hideLoading
app.config.globalProperties.$toolUtils = toolUtils
app.mount('#app')
