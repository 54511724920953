<template>
    <div class="dbody">
        <div class="login-box">
            <div class="login-title">
                <span :class="{active: isActive == 1}" style="border-right: 1px solid #d4d4d4;" @click="genQrConnect">{{$t('login.login_1')}}</span>
                <span :class="{active: isActive == 2}" @click="change(2)">{{$t('login.login_2')}}</span>
            </div>
            <el-form v-if="isActive == 2" ref="loginForm1" :model="form1" :rules="rules1" label-width="80px">
                <el-form-item :label="$t('login.login_4')" prop="email">
                    <el-input v-model="form1.email" id="email" type="text" placeholder="" />
                </el-form-item>
                <el-form-item :label="$t('login.login_5')" prop="code" style="margin-bottom:0px">
                    <div style="display: flex">
                        <el-input v-model="form1.code" type="text" class="code" :placeholder="$t('login.login_6')" />
                        <el-button style="width: 150px" :disabled="codeMsg!=''&&codeMsg!=undefined" @click="sendMail">{{codeMsg?codeMsg:$t('login.login_7')}}</el-button>
                    </div>

                </el-form-item>
                <el-button type="primary" style="width:100%;margin-top: 20px" @click="submit">{{$t('login.login_8')}}</el-button>
                <div class="type-list">
                </div>
            </el-form>
            <div v-show="isActive == 1" class="login-qrcode">
                <img v-if="qrcode" :src="qrcode">
                <div v-else style="position: relative">
                    <img :src="require('@/assets/invalidQR.png')">
                    <div @click="genQrConnect" style="position: absolute;top: calc(50% - 10px);left: calc(50% - 50px);width: 100px;font-weight: bold;cursor: pointer">点击刷新验证码</div>
                </div>
            </div>
            <div v-show="isActive == 1" class="font" v-html="$t('login.login_3')"></div>
        </div>
    </div>
</template>
<script>
import { msgSuccess, msgWarning, msgError } from '@/utils/message' // 引入封装的消息提示
import { setToken, setRefreshToken } from '@/utils/auth'
import request from '@/utils/request'

export default {
  components: {
  },
  props: {
    success: {
      type: Function,
      default: undefined
    }
  },
  data () {
    return {
      isActive: 1,
      codeMsg: '',
      identify: '',
      showQrCode: false,
      scode: '',
      lockTime1: false,
      bindJudgeDialog: false,
      planOptions: [],
      isShow: false,
      verifyImgDialog: false,
      verifyImgWidth: 308,
      verifyImgData: { blockY: undefined, imgUrl: undefined, miniImgUrl: undefined, verifyImgId: undefined, moveLength: undefined },
      form: {
        email: '',
        code: ''
      },
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules: {
        email: [
          { required: true, message: 'Email cannot be empty.', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '验证码不可为空', trigger: 'blur' }
        ]
      },
      form1: {
        email: '',
        code: '',
        planId: ''
      },
      // 表单验证，需要在 el-form-item 元素中增加 prop 属性
      rules1: {
        email: [
          { required: true, message: 'Email cannot be empty.', trigger: 'blur' }
        ],
        code: [
          { required: true, message: 'The verification code cannot be empty.' }
        ]
      },
      qrcode: '',
      state: '',
      timre: null,
      timer1: null,
      timer2: null,
      dialogPayVisible: false,
      bind: {
        name: '',
        cid: '',
        mobilePhone: '',
        identity: '',
        smsId: '',
        verificationCode: ''
      },
      openId: '',
      qrTime: ''

    }
  },
  created () {
    this.genQrConnect()
      var that = this
    setInterval(() => {
        if (new Date().getTime() - that.qrTime > 50000) {
            that.qrcode = ''
            if (that.timre != null) {
                clearInterval(that.timre)
            }
        }
    }, 1000)
  },
  mounted () {
  },
  unmounted () {
    if (this.timre != null) {
      clearInterval(this.timre)
    }
    if (this.timre2 != null) {
      clearInterval(this.timre2)
    }
  },
  methods: {
      sendMail () {
      if (!this.form1.email) {
        msgWarning('Please enter your email first！')
        return
      }

      var params = { email: this.form1.email}
        this.$showLoading()
      request({
        url: '/wali/login/getCode',
        method: 'get',
        params
      }).then(res => {
          this.$hideLoading()
        if (res.code === 0) {
          this.form1.smsId = res.data
          this.setTime(60)
        } else {
          msgWarning(res.msg)
          this.disabled = false
          this.codeMsg = ''
        }
      })
    },
      submit() {
          var params = { email: this.form1.email, id: this.form1.smsId, code: this.form1.code, receiveCode: localStorage.getItem('receiveCode') }
          request({
              url: '/wali/login/emailLogin',
              method: 'post',
              params
          }).then(res => {
              if (res.code === 0) {
                  this.handleLogin(res.data.token, res.data.refreshToken)
              } else {
                  msgWarning(res.msg)
              }
          })
      },
    getIdentify () {
      const that = this
      request({
        url: '/hrss/rc/examination/examUser/getImgCode',
        method: 'get',
        data: {}
      }).then(res => {
        console.log(res)
        that.identifyKey = res.data.identifyKey
        that.identify = 'data:image/png;base64,' + res.data.identify
      })
    },
    setTime (counts) {
      if (counts === 0) {
        counts = 60
        this.codeMsg = ''
        return false
      } else {
        this.codeMsg = this.$t('login.login_7') + '（' + counts + '）'
        counts--
      }
      var that = this
      setTimeout(function () {
        that.setTime(counts)
      }, 1000)
    },
    handlerSuccess (url) {
      if (this.success) {
        this.success()
      } else {
        window.parent.location.href = url
      }
    },
    getUserLogin () {
      var active = this.isActive
      if (active === 1) {
        request({
          url: '/wali/wxAuth/getUserLogin',
          method: 'post',
          data: { receiveCode: localStorage.getItem('receiveCode') }
        }).then(res => {
          if (res.code === 0) {
              this.openId = res.data.openId
              if (this.timre != null) {
                  clearInterval(this.timre)
              }
              this.handleLogin(res.data.token, res.data.refreshToken)
          }
        })
      }
    },
    handleLogin (token, refreshToken) {
      setToken(token)
      setRefreshToken(refreshToken)
      msgSuccess('Welcome')
      if (this.success) {
        this.success()
      } else {
        window.location.reload()
      }
    },
    genQrConnect() {
      if (this.qrcode) {
          return
      }
      this.$showLoading()
      request({
        url: '/wali/wxAuth/genQrConnect',
        method: 'get'
      }).then(res => {
        console.log(res)
        if (res.code === 0) {
          this.qrcode = res.data.qrcode
          this.state = res.data.state
          this.isActive = 1
            if (this.timre != null) {
                clearInterval(this.timre)
            }
          this.timre =  setInterval(this.getUserLogin, 1000)
          this.qrTime = new Date().getTime()
        } else {
          msgError(res.msg)
        }
        this.$hideLoading()
      }).catch(() => {
        this.$hideLoading()
      })
    },
    onSubmit (formName) {
      // 为表单绑定验证功能
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var data = { email: this.form.email, code: this.$bcrypt.hashSync(this.$md5(this.form.code), 10) }
          data.moveLength = this.verifyImgData.moveLength
          data.verifyImgId = this.verifyImgData.verifyImgId
          request({
            url: '/hrss/rc/examination/examUser/loginPerson',
            method: 'post',
            data
          }).then(res => {
            if (res.code === 0 && res.data && res.data.token) {
              setToken(res.data.token)
              setRefreshToken(res.data.refreshToken)
              if (this.success) {
                this.success()
              } else {
                window.location.href = this.$toolUtils.personalBaseUrl + 'applyList'
              }
            } else if (res.code === -2) {
              msgError(res.msg)
              this.onRefresh()
            } else {
              this.verifyImgDialog = false
              msgError(res.msg)
            }
          })
        }
      })
    },
    // 获取图片验证码
    getImageVerifyCode () {
      var that = this
      var formName = 'loginForm'
      if (this.isActive == 2) {
        formName = 'loginForm1'
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$toolUtils.getVerifyImg().then(res => {
            if (res.code !== 0) {
              msgWarning('获取图形验证码失败，请重试')
            } else {
              var imgobj = res.data
              that.verifyImgData.blockY = imgobj.yHeight
              that.verifyImgData.oriImageWidth = imgobj.oriImageWidth
              that.verifyImgData.imgUrl = imgobj.bigImage
              that.verifyImgData.miniImgUrl = imgobj.smallImage
              that.verifyImgData.verifyImgId = imgobj.verifyImgId
              this.verifyImgDialog = true
              setTimeout(() => {
                this.$refs.verifyImg.reset()
              })
            }
          })
        }
      })
    },
    // 刷新
    onRefresh () {
      this.verifyImgData = { blockY: undefined, imgUrl: undefined, miniImgUrl: undefined, verifyImgId: undefined, moveLength: undefined }
      this.getImageVerifyCode()
    },
    // 滑动结束, l是滑动距离
    onSuccess (l) {
      this.verifyImgData.moveLength = l
      if (this.isActive == 1) {
        this.onSubmit('loginForm')
      } else if (this.isActive == 2) {
        this.onSubmit1('loginForm1')
      }
    },
    register () {
      this.$router.push({name: 'examRegistration_bak', params: {oid: this.openId}})
    },
    change (i) {
      if (i == 1) {
        this.isActive = 1
      } else if (i == 2) {
        this.isActive = 2
      }
    }
  }
}
</script>

<style lang="scss">
    .dbody{
        .loginHeader{
            background: #E8EBED;
            .loginHeader-main{
                width: 1200px;
                height: 50px;
                line-height: 50px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                padding: 0 30px;
                box-sizing: border-box;
                .title{
                    font-weight: 600;
                    font-size: 20px;
                }
                .back{
                    font-size: 14px;
                    cursor: pointer;
                }
            }
        }
        .main_cotent{
            width: 1200px;
            margin: 100px auto;
            display: flex;
            justify-content: space-around;
        }
        .login-box {
            margin: auto;
            border: 1px solid #DCDFE6;
            border-radius: 10px;
            box-shadow: 0px 3px 18px 0px rgb(0 0 0 / 15%);
            background-color: #fff;
            width: 370px;
            height: 443px;
            padding: 10px 30px;
            box-sizing: border-box;
        }

        .login-title {
            text-align: center;
            margin: 0px auto 40px;
            color: #303133;
            position: relative;
            .login-qr-code {
                position: absolute;
                width: 37px;
                height: 37px;
                left: -20px;
                top: 0px;
                padding: 4px;
                cursor: pointer;
                transform: rotateY(-180deg);
            }
            span {
                cursor: pointer;
                font-size: 18px;
                font-weight: 400;
                color: #a2a2a2;
                display: inline-block;
                width: calc(50% - 1px);
                text-align: center;
                height: 30px;
                line-height: 30px;
                margin-top: 20px;
            }
            span.active {
                font-weight: bold;
                color: #0178d4;
            }
        }
        .tip {
            margin-bottom: 20px;
            font-size: 14px;
            margin-top: 20px;
            padding: 0 10px;
            span {
                color: #0178d4;
                cursor: pointer;
            }
        }
        .type-list {
            display: flex;
            justify-content: space-around;
            text-align: center;
            .type-box {
                cursor: pointer;
                img {
                    width: 43px;
                    height: 43px;
                    margin-bottom: 5px;
                }
                .type-name {
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
        .login-qrcode {
            margin-left: 50%;
            transform: translateX(-50%);
            width: 200px;
            height: 200px;
            padding: 10px;
            border: 1px solid #ccc;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .el-dialog .el-dialog__header span.el-dialog__title {
        position: relative;
        line-height: 20px;
        display: inline-block;
        font-weight: 700;
        font-size: 14px;
        // color: #fff;
    }
    /*.el-dialog__header {
        padding: 10px 10px 10px;
    }*/
    .el-dialog__wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .font{
        font-size: 14px;
        margin-top: 10px;
        text-align: center;
    }
    div.popContainer {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
        z-index: 999;
    }
    .el-input-group__append {
        padding: 0px;
    }
    .codeBtn {
        width: 180px;
    }

</style>
<style lang="less" scoped>
    .code {
        /deep/ .el-input__inner {
            -webkit-text-security: disc !important;
        }
    }
</style>
