import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const refreshKey = 'Refresh-Token'
export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getRefreshToken() {
  return Cookies.get(refreshKey)
}

export function setRefreshToken(token) {
  return Cookies.set(refreshKey, token)
}
