// 2021.01.08 这个文件，待修改...
import axios from 'axios'
import { ElMessage as Message } from 'element-plus'
import { getToken,removeToken,setToken,getRefreshToken,setRefreshToken} from '@/utils/auth'
// create an axios instance
const service = axios.create({
  baseURL: '', // url = base url + request url
  timeout: 30000 // request timeout
})

const tokenHeader = 'authorization'
const refreshHeader = 'refresh-auth'

// request interceptor
service.interceptors.request.use(config => {
    if (getToken()) {
      config.headers[tokenHeader] = getToken()
    }
    if (getRefreshToken()) {
        config.headers[refreshHeader] = getRefreshToken()
    }
    if(config['resType'] ==='formType'){
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
    }else{
      config.headers['Content-Type'] = 'application/json; charset=utf-8'
    }
    config.headers['Referer-Flag'] = 'portal'
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(response => {
    console.log('返回数据1',response)
    const res = response
    if (response.headers[tokenHeader]) {
        setToken(response.headers[tokenHeader])
    }
    if (response.headers[refreshHeader]) {
        setRefreshToken(response.headers[refreshHeader])
    }
    if(res.status == 200){
      return res.data
    }else if(res.status == 403){
      Message({
        message: '请重新登录',
        type: 'error',
        duration: 2 * 1000
      })
      removeToken();
        var myEvent = new CustomEvent('toLogin')
        window.dispatchEvent(myEvent)
    }else{
      if (res instanceof Blob) {
        return res.data
      }
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      });
      return Promise.reject(new Error(res.msg || 'Error'))
    }
  },
  error => {
    if(error.toString().includes('403')){
      Message({
        message: '请重新登录',
        type: 'error',
        duration: 2 * 1000
      })
      removeToken();
      var myEvent = new CustomEvent('toLogin')
      window.dispatchEvent(myEvent)
      return
    }
    console.error('err' + error) // for debug
    return Promise.reject(error)
  }
)

export default service
