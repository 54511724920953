export default {
  login: {
    title: 'LOGIN',
    login_1: 'WeChat',
    login_2: 'Email',
    // login_3: '<p>Please use WeChat\'s</p> <p>scan-to-login feature</p>'
    login_3: 'Please use WeChat\'s scan-to-login feature',
    login_4: 'E-MAIL',
    login_5: 'CODE',
    login_6: '',
    login_7: 'SEND',
    login_8: 'SIGN UP / LOGIN'
  },
  file: {
    file_1: 'upload to server',
    file_2: 'Drop file here or <em>click to upload</em>',
    file_3: '‌The total size of all files must not exceed 100MB'
  },
  convert: {
    convert_0: 'Convert',
    convert_1: 'Pdf to Word',
    convert_2: 'Pdf to Excel',
    convert_3: 'Pdf to Image',
    convert_4: 'Pdf to Ppt',
    convert_5: 'Excel to Pdf',
    convert_6: 'Word to Pdf',
    convert_7: 'Word to Pdf'
  },
  organize: {
    organize_0: 'Organize',
    organize_1: 'Merge Pdf',
    organize_2: 'Split Pdf',
    organize_3: 'Merge Word',
    organize_4: 'Split Word'
  },
  head: {
    msg_1: 'Account',
    msg_2: 'Log out'
  },
  mergePdf: {
    msg_1: 'Merge Pdf'
  }
}
