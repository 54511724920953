import { ElMessageBox as MessageBox, ElMessage as Message, Notification } from 'element-plus'

// 确认框：（取消+确认），有四种类型： warning、success、error、info
export function handleConfirm(text = '确定执行此操作吗？', type = 'warning') {
  return MessageBox.confirm(`<div class="myalert-header">操作提醒</div><div class="myalert-content">${text}</div></div>`, {
    confirmButtonText: '确定',
    dangerouslyUseHTMLString: true,
    cancelButtonText: '取消',
    type: type
  })
}

// alert确认框：（确认） ，有四种类型： warning、success、error、info
export function handleAlert(msg = '操作失败', type = 'warning') {
  return MessageBox.alert(
    `<div class="myalert-header">操作失败</div>
    <div class="myalert-content">${msg}</div>`, {
      dangerouslyUseHTMLString: true,
      confirmButtonText: '确定',
      type: type
    })
}

// 提示：成功
export function msgSuccess(msg = '操作成功', type = 'success') {
  return Message({
    type: type,
    dangerouslyUseHTMLString: true,
    message: `${msg}`,
    duration: 2000
  })
}

// 提示：警告
export function msgWarning(msg = '操作成功') {
  return Message({
    type: 'warning',
    dangerouslyUseHTMLString: true,
    message: `<p>${msg}</p>`,
    duration: 2000
  })
}
// 提示：普通消息
export function msgInfo(msg = '操作成功') {
  return Message({
    dangerouslyUseHTMLString: true,
    message: `<p>${msg}</p>`,
    duration: 2000
  })
}

// 提示：错误
export function msgError(msg = '操作成功') {
  return Message({
    type: 'error',
    dangerouslyUseHTMLString: true,
    message: `<p>${msg}</p>`,
    duration: 2000
  })
}

// 通知： 有四种类型： warning、success、error、info
export function notify(msg = '提示', type = 'warning') {
  return Notification({
    title: msg,
    type: type,
    duration: 3000,
  })
}
