// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import trans from "@/page/transFile/index";
import mergePdf from "@/page/transFile/mergePdf";
import index from "@/page/index";
const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/pdf-docx',
    name: 'pdf-docx',
    component: trans
  },
  {
    path: '/pdf-xlsx',
    name: 'pdf-xlsx',
    component: trans
  },
  {
    path: '/pdf-png',
    name: 'pdf-png',
    component: trans
  },
  {
    path: '/pdf-PowerPoint',
    name: 'pdf-PowerPoint',
    component: trans
  },
  {
    path: '/pdf-ppt',
    name: 'pdf-ppt',
    component: trans
  },
  {
    path: '/word-pdf',
    name: 'word-pdf',
    component: trans
  },
  {
    path: '/xlsx-pdf',
    name: 'xlsx-pdf',
    component: trans
  },
  {
    path: '/mergePdf',
    name: 'mergePdf',
    component: mergePdf
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
