import request from '@/utils/request'
import axios from 'axios'
import { getToken, getRefreshToken } from '@/utils/auth'
import Cookies from 'js-cookie'
import moment from 'moment'
import { showLoading, hideLoading } from '@/utils/loading'
export default({
    opCodeUrl: 'https://wpt.cs12333.com/ui/wx/#/codeParm?dv=1807',
    encodeCSRC(input) {
        var keyStr = "ABCDEFGHIJKLMNOP" + "QRSTUVWXYZabcdef" + "ghijklmnopqrstuv"
            + "wxyz0123456789+/" + "=";
        var output = "";
        var chr1, chr2, chr3 = "";
        var enc1, enc2, enc3, enc4 = "";
        var i = 0;
        do {
            chr1 = input.charCodeAt(i++);
            chr2 = input.charCodeAt(i++);
            chr3 = input.charCodeAt(i++);
            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;
            if (isNaN(chr2)) {
                enc3 = enc4 = 64;
            } else if (isNaN(chr3)) {
                enc4 = 64;
            }
            output = output + keyStr.charAt(enc1) + keyStr.charAt(enc2)
                + keyStr.charAt(enc3) + keyStr.charAt(enc4);
            chr1 = chr2 = chr3 = "";
            enc1 = enc2 = enc3 = enc4 = "";
        } while (i < input.length);

        return output;
    },
    regUsername(val) {
        //用户名正则，6到16位（字母，数字，下划线，减号）
        var uPattern = /^[a-zA-Z0-9_-]{6,16}$/;
        return uPattern.test(val);
    },
    regPassword(val) {
        // 6到20位必须有字母数字可以有（可有可没有）特殊字符的组合
        var pattern = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,20}$/;
        return pattern.test(val)
    },
    regMobilePhone(val) {
        var pattern = /^1\d{10}$|^0\d{2,3}-?\d{7,8}$/;
        return pattern.test(val)
    },
    getDictParams(dictCodes) {
        return request({
            url: '/hrss/rc/its/param/web/dictparam/getDictParamTableMultiple?dictCodes=' + dictCodes + '&isCustomSort=1',
            method: 'get'
        })
    },
    // 对Date的扩展，将 Date 转化为指定格式的String
    // 月(M)、日(d)、小时(H)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
    // 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
    // 例子：yyyy-MM-dd hh:mm:ss.S
    dateFormmater(date, fmt) { // author: meizz
        var o = {
        'M+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours(), // 小时
        'm+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        'S': date.getMilliseconds() // 毫秒
        }
        if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length)) }
        for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
        }
        return fmt
    },
    getCurWeek() {
        var json = { 0: '星期日', 1: '星期一', 2: '星期二', 3: '星期三', 4: '星期四', 5: '星期五', 6: '星期六' }
        return json[new Date().getDay()]
    },
    // 将返回的流数据转换为url
    getObjectURL(file) {
        let url = null;
        if (window.createObjectURL != undefined) {
            // basic
            url = window.createObjectURL(file);
        } else if (window.webkitURL != undefined) {
            // webkit or chrome
            try {
                url = window.webkitURL.createObjectURL(file);
            } catch (error) {
                console.error(error)
            }
        } else if (window.URL != undefined) {
            // mozilla(firefox)
            try {
                url = window.URL.createObjectURL(file);
            } catch (error) {
                console.error(error)
            }
        }
        console.log(url);
        return url;
    },
    downloadFile(url, data, requestMethod) {
        showLoading()
      axios({
        method: requestMethod?requestMethod:'POST',
        url: url,
        data,
        headers: {
          'authorization': getToken(),
          'refresh-auth': getRefreshToken(),
          'Referer-Flag': 'portal'
        },
        responseType: 'arraybuffer'
      }).then(response => {
          this.handleBlobRes(response)
          hideLoading()
      }).catch(err => {
          hideLoading()
        console.error(err)
      })
    },
    handleBlobRes(response) {
        const fileType = [
            'application/vnd.ms-excel;charset=utf-8', // xls
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8', // xlsx
            'application/msword',// doc
            'application/pdf',
            'application/zip',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // docx
        ]
        const blob = new Blob([response.data], { type: fileType })
        const downloadElement = document.createElement('a')
        const href = window.URL.createObjectURL(blob)
        const contentDisposition = response.headers['content-disposition'] // 从response的headers中获取filename, 后端response.setHeader("Content-disposition", "attachment; filename=xxxx.docx") 设置的文件名;
        if(contentDisposition){
            const patt = new RegExp('filename=([^;]+\\.[^\\.;]+);*')
            const result = patt.exec(contentDisposition)
            const filename = decodeURI(result[1]) // 处理文件名,解决中文乱码问题
            downloadElement.download = filename // 下载后文件名
        } else {
            downloadElement.download = new Date().getTime()
        }
        downloadElement.style.display = 'none'
        downloadElement.href = href
        document.body.appendChild(downloadElement)
        downloadElement.click() // 点击下载
        document.body.removeChild(downloadElement) // 下载完成移除元素
        window.URL.revokeObjectURL(href) // 释放掉blob对象
    },
    // 判断是否为空
    isNull(str) {
      if (str === null || str === undefined || str === 'undefined' || str === '' || str.toUpperCase === 'NULL' || str === []) {
        return true
      }
      return false
    },
    getCookie(key){
      return Cookies.get(key)
    },
    setCookie(key,val){
      Cookies.set(key,val)
    },
      // 1、yyyy-mm-dd 2、yyyy-mm-dd hh:mi:ss
    momentDate(value, format) {
      if (!format) {
        format = 'YYYY年MM月DD日 HH:mm:ss'
      }
      if (value) {
        return moment(value).format(format)
      } else {
        return ''
      }
    },
    getVerifyImg() {
      return request({
        url: '/hrss/rc/examination/examUser/getVerifyImg',
        method: 'get'
      })
    },
    // 获取url参数
  getQueryVariable(variable) {
    var search = window.location.href.split('?')[1]
    if (search && search.endsWith('#/')) {
       search = search.substring(0, search.length - 2)
    }
    var param = new URLSearchParams('?' + search)
    return param.get(variable)
  },
})
