<template>
    <div style="padding: 20px 50px 20px 50px">
        <el-upload
            ref="file"
            class="upload-demo"
            v-model:file-list="fileList"
            drag
            :limit="10"
            multiple
            @success="handleSuccess"
            :auto-upload="false"
        >
            <template #trigger>
                <img class="el-icon--upload" style="width: 50px;" :src="require('@/assets/icon/upload-icon.png')" />
                <div class="el-upload__text" v-html="$t('file.file_2')">
                </div>
            </template>

            <el-button style="margin-top: 10px;background-color: #f7ab8e" type="success" @click="submitUpload">
                {{$t('mergePdf.msg_1')}}
            </el-button>

            <template #tip>
                <div class="el-upload__tip">
                    {{$t('file.file_3')}}
                </div>
            </template>
        </el-upload>
    </div>
</template>

<script>
export default {
    components: {
    },
    data() {
        return {
            dialogVisible: false,
            fileList: []
        }
    },
    create() {
    },
    methods: {
        submitUpload() {
            let data = new FormData();
            this.fileList.map(item => {
                data.append('file', item.raw)
            })
            this.$toolUtils.downloadFile('/wali/file/mergePdf', data)
        }
    }
}
</script>
