export default {
  login: {
    title: '登录',
    login_1: '微信登录',
    login_2: '邮箱登录',
    login_3: '请使用微信扫一扫登录，首次登录需关注公众号',
    login_4: '邮箱账号',
    login_5: '验证码',
    login_6: '请输入邮箱验证码',
    login_7: '发送',
    login_8: '注册 / 登录'
  },
  file: {
    file_1: '提 交',
    file_2: '将文件托到此处或点击上传',
    file_3: '一次性最多上传10个文件，文件总大小不能大于100Mb'
  },
  convert: {
    convert_0: '转换',
    convert_1: 'Pdf to Word',
    convert_2: 'Pdf to Excel',
    convert_3: 'Pdf to Image',
    convert_4: 'Pdf to Ppt',
    convert_5: 'Excel to Pdf',
    convert_6: 'Word to Pdf',
    convert_7: 'Word to Pdf'
  },
  organize: {
    organize_0: '整理',
    organize_1: '合并pdf',
    organize_2: '拆分pdf',
    organize_3: '合并word',
    organize_4: '拆分word'
  },
  head: {
    msg_1: '账户',
    msg_2: '退出登录'
  },
  mergePdf: {
    msg_1: '合并Pdf'
  }
}
