<template>
  <div style="padding: 0px 8px 0px 8px;">
    <div style="display: flex;justify-content: space-between;height: 60px">
      <div style="display:flex;">
        <img style="height: 100%" :src="require('@/assets/logo.png')" />
        <div style="line-height: 60px;font-family: fantasy;font-size: large">Office Tools</div>
      </div>
      <div style="display: flex;flex-direction: column;justify-content: center;padding-right: 12px">
        <el-dropdown v-if="userData.id" placement="bottom-end">
          <img style="width: 40px;cursor: pointer" :src="require('@/assets/avatar/avatar4.png')">
          <template #dropdown>
            <el-dropdown-menu>
              <!--<el-dropdown-item>{{$t('head.msg_1')}}</el-dropdown-item>-->
              <el-dropdown-item @click="logout">{{$t('head.msg_2')}}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>

        <el-button v-else @click="dialogVisible=true" style="background-color: #6e88c8;color: white;letter-spacing:2px;">{{$t('login.title')}}</el-button>
      </div>
    </div>
    <div style="display: flex">
      <el-menu
        active-text-color="#ee7e9d"
        background-color="#6175bd"
        :collapse="false"
        class="menu"
        default-active="2"
        text-color="#fff"
        @open="handleOpen"
        @close="handleClose"
        @select="handleSelect"
      >
        <el-sub-menu index="1">
          <template #title>
            <el-icon><location /></el-icon>
            <span>{{$t('convert.convert_0')}}</span>
          </template>
          <el-menu-item index="pdf-docx">{{$t('convert.convert_1')}}</el-menu-item>
          <el-menu-item index="pdf-xlsx">{{$t('convert.convert_2')}}</el-menu-item>
          <el-menu-item index="pdf-png">{{$t('convert.convert_3')}}</el-menu-item>
          <el-menu-item index="pdf-ppt">{{$t('convert.convert_4')}}</el-menu-item>
          <el-menu-item index="xlsx-pdf">{{$t('convert.convert_5')}}</el-menu-item>
          <el-menu-item index="word-pdf">{{$t('convert.convert_6')}}</el-menu-item>
        </el-sub-menu>
        <el-sub-menu index="2">
          <template #title>
            <el-icon><location /></el-icon>
            <span>{{$t('organize.organize_0')}}</span>
          </template>
          <el-menu-item index="mergePdf">{{$t('organize.organize_1')}}</el-menu-item>
          <el-menu-item index="splitPdf">{{$t('organize.organize_2')}}</el-menu-item>
        </el-sub-menu>
      </el-menu>
      <router-view :key="$route.name" class="content"/>
    </div>
  </div>
  <el-dialog v-model="dialogVisible" title="Login to Office Tools" width="500" destroy-on-close :close-on-click-modal="false">
    <loginBox :success="getUser" />
  </el-dialog>
  <div class="foot">
    <a href="https://beian.miit.gov.cn/">湘ICP备2023004094号-4</a>
  </div>
</template>

<script>
import request from '@/utils/request'
import loginBox from "./components/loginBox";
import { removeToken } from '@/utils/auth'
export default {
  name: 'App',
  components: {
    loginBox
  },
  data() {
    return {
      dialogVisible: false,
      isCollapse: true,
      activeIndex: 0,
      userData: {}
    }
  },
  created() {
    var that = this
    window.addEventListener('toLogin', () => {
      that.dialogVisible = true
    })
    if (this.$toolUtils.getQueryVariable('pid')) {
      localStorage.setItem('receiveCode', this.$toolUtils.getQueryVariable('pid'))
    }
    this.getUser()
  },
  methods: {
    getUser() {
      request({
        url: '/wali/user/getCurrentUser',
        method: 'get'
      }).then(res => {
        if (res && res.code === 0) {
          this.userData = res.data
          this.dialogVisible = false
        }
      })
    },
    handleSelect(v) {
      this.$router.push(v)
    },
    handleOpen(v) {
      console.log(v)
    },
    handleClose(v) {
      console.log(v)
    },
    logout() {
      removeToken()
      this.userData = {}
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.menu {
  width: 200px;
}
.content {
  width: calc(100% - 150px);
}
.foot {
  position: fixed;
  bottom: 0px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  width: 100%;
  background-color: #6e88c8;
  a {
    color: white;
  }
}
</style>
